<template>
  <div>
    <v-snackbar v-model="showAddedMessage" :timeout="2000" color="success">{{ $t("main.ADDED_MESSAGE") }}</v-snackbar>

    <div v-if="!organisation">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div v-if="organisation">
      <div class="select-default" v-if="allCountries == 'no'">
        <div class="block-title">
          {{ $t("main.COUNTRY_SELECTION_DEFAULT_FOR_QUERY") }}
        </div>
        <div class="default-query-input">
          <v-autocomplete
              v-model="defaultCountry"
              :items="countries"
              @change="changeDefaultCountry"
              @click:clear="clearDefaultCountry"
              item-text="nameShort"
              item-value="iso2"
              return-object
              :label="$t('main.COUNTRY_SELECTION_DEFAULT_DEPARTURE')"
              height="50px"
              style="padding-inline: 8px"
              clearable
              :loading="countriesIsLoading"
          />
          <v-autocomplete
              v-model="defaultNationality"
              :items="countries"
              @change="changeDefaultNationality"
              @click:clear="clearDefaultNationality"
              item-text="nameShort"
              item-value="iso2"
              return-object
              :label="$t('main.COUNTRY_SELECTION_DEFAULT_NATIONALITY')"
              height="50px"
              style="padding-inline: 8px"
              clearable
              :loading="countriesIsLoading"
          />
        </div>
      </div>

      <div class="select-favorites" v-if="allCountries == 'no'">
        <div>
          <div class="block-title">
            {{ $t("main.COUNTRY_SELECTION_ADD_COUNTRY") }}
          </div>
          <v-autocomplete
              v-model="country"
              :search-input.sync="countrySearch"
              @change="onCountry"
              :items="countries"
              item-text="nameShort"
              item-value="iso2"
              return-object
              :label="$t('main.COUNTRY')"
              height="50px"
              style="padding-inline: 8px"
              :messages="$t('main.COUNTRY_SELECTION_INTRO')"
              :loading="countriesIsLoading"
          />
        </div>
        <v-row class="pt-6">
          <v-col cols="6">
            <div class="block-title">
              {{ $t("main.YOUR_FAVORITES") }}
            </div>
            <div v-if="organisationCountrySelectionsIsDone">
              <v-list :three-line="organisationCountrySelections.length == 0" class="py-0">
                <template v-for="(item, index) in organisationCountrySelections">
                  <v-list-item :key="item.id">
                    <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.country.nameShort }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" @click="deleteCountrySelection(item.id)" :loading="deleteInProgress[item.id]">-</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider  v-if="index + 1 < organisationCountrySelections.length" :key="item.id+'__divider'"></v-divider>
                </template>
                <v-list-item v-if="organisationCountrySelections.length == 0">
                  <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("main.COUNTRY_SELECTION_SHOW_ALLCOUNTRIES") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("main.COUNTRY_SELECTION") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <div class="list-notice" v-if="organisationCountrySelections.length > 0">
                  {{ $t("main.COUNTRY_SELECTION_CLEAN_NOTICE") }}
                </div>
              </v-list>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="block-title">
              {{ $t("main.AVAILABLE_COUNTRIES") }}
            </div>
            <v-expansion-panels class="all-countries-panel">
              <v-expansion-panel v-for="letter in alphabet" :key="letter">
                <v-expansion-panel-header>{{ letter }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template v-for="(item, index) in filteredCountriesByLetter(letter)" >
                      <v-list-item :key="item.id">
                        <v-list-item-avatar><v-icon color="gray lighten-1">language</v-icon></v-list-item-avatar>
                        <v-list-item-content>{{ item.nameShort }}</v-list-item-content>
                        <v-list-item-action>
                          <v-btn color="primary" @click="addFavorite(item.id)" :loading="addInProgress[item.id]">+</v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider  v-if="index + 1 < filteredCountriesByLetter(letter).length" :key="item.id+'__divider'"></v-divider>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: 'CountrySelectionView',
  data() {
    return {
      allCountries: "no",
      country: null,
      defaultCountry: null,
      defaultNationality: null,
      countrySearch: '',
      showAddedMessage: false,
      deleteInProgress: {},
      addInProgress: {},
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    }
  },
  mounted: function() {
    this.countriesFetchAll().then(() => {
      this.defaultCountry = this.countries.find(country => country.iso2 === this.localStorageDefaultCountry);
      this.defaultNationality = this.countries.find(country => country.iso2 === this.localStorageDefaultNationality);
    });
    this.doOrganisationCountrySelectionsFetchAll()
  },
  computed: {
    ...mapState({
      localStorageDefaultCountry: state => state.uiStore.defaultCountry,
      localStorageDefaultNationality: state => state.uiStore.defaultNationality,
      organisation: state => state.authStore.organisation,
      countries: state => state.countriesStore.entries,
      countriesIsDone: state => state.countriesStore.isDone,
      countriesIsLoading: state => state.countriesStore.isLoading,
      organisationCountrySelections: state => state.organisationCountrySelectionsStore.entries,
      organisationCountrySelectionsIsDone: state => state.organisationCountrySelectionsStore.isDone,
      organisationCountrySelectionsIsLoading: state => state.organisationCountrySelectionsStore.isLoading,
    }),
    nonFavoriteCountries() {
      let nonFavoriteCountries = []
      for (let country of this.countries) {
        let isFavorite = this.organisationCountrySelections.some(el => el.country.id == country.id)
        if (!isFavorite) {
          nonFavoriteCountries.push(country)
        }
      }
      return nonFavoriteCountries
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll",
      organisationCountrySelectionsFetchAll: "organisationCountrySelectionsStore/fetchAll",
      organisationCountrySelectionsCreate: "organisationCountrySelectionsStore/create",
      organisationCountrySelectionsDelete: "organisationCountrySelectionsStore/delete"
    }),
    ...mapMutations({
      SET_DEFAULT_COUNTRY: 'uiStore/SET_DEFAULT_COUNTRY',
      SET_DEFAULT_NATIONALITY: 'uiStore/SET_DEFAULT_NATIONALITY',
    }),
    doOrganisationCountrySelectionsFetchAll() {
      this.organisationCountrySelectionsFetchAll({
        force: true
      });
    },
    onCountry() {
      this.addFavorite(this.country.id)
    },
    changeDefaultCountry(){
      if (this.defaultCountry) {
        this.SET_DEFAULT_COUNTRY(this.defaultCountry.iso2)
      }
    },
    changeDefaultNationality(){
      if (this.defaultNationality) {
        this.SET_DEFAULT_NATIONALITY(this.defaultNationality.iso2)
      }
    },
    addFavorite(countryId) {
      this.addInProgress[countryId] = true
      let alreadyInList = this.organisationCountrySelections.some(el => el.country.id == countryId)

      if (!alreadyInList) {
        this.organisationCountrySelectionsCreate({
          variables: {
            input: {
              organisation: this.organisation.id,
              country: countryId
            }
          }
        }).then(() => {
          this.doOrganisationCountrySelectionsFetchAll()
          this.showAddedMessage = true
          this.addInProgress[countryId] = false
        })
      }

      this.$nextTick(() => {
        this.country = null
        this.countrySearch = ''
      })
    },

    deleteCountrySelection(countrySelectionId) {
      this.$set(this.deleteInProgress, countrySelectionId, true)

      this.organisationCountrySelectionsDelete({
        id: countrySelectionId
      }).then(() => {
        this.doOrganisationCountrySelectionsFetchAll()
        this.$set(this.deleteInProgress, countrySelectionId, false)
      }).catch(() => {
        this.$set(this.deleteInProgress, countrySelectionId, false)
      })

    },
    filteredCountriesByLetter(letter) {
      return this.nonFavoriteCountries.filter(country => country.nameShort.startsWith(letter));
    },
    clearDefaultCountry() {
      this.defaultCountry = null
      this.SET_DEFAULT_COUNTRY(this.defaultCountry)
    },
    clearDefaultNationality() {
      this.defaultNationality = null
      this.SET_DEFAULT_NATIONALITY(this.defaultNationality)
    }
  },
  watch: {
  }
}
</script>

<style scoped>
  .list-notice {
    margin-top: 2rem;
    color: gray;
  }

  .all-countries-panel{
    margin-top: 1rem;
    padding-inline: 0.5rem;
  }

  .default-query-input{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .select-default, .select-favorites {
    background-color: #FFFFFF;
    padding: 18px;
    border-radius: 0.625rem;
    margin-bottom: 2.7%;
  }

</style>