<template>
  <div class="settings-view">
    <div class="profile-settings">
      <div class="settings-sidebar">
        <SettingsNavigation />
      </div>
      <div class="settings-main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from "@/components/settings/SettingsNavigation";

export default {
  name: 'SettingsView',
  components: {
    SettingsNavigation,
  },
}
</script>

<style>
  .settings-view {
    display: flex;
    flex-direction: column;
    padding-inline: 1.5%;
    background: #F7F9FC;
    height: 100%;
  }

  .profile-settings {
    height: 100%;
    gap: 1%;
    padding-block: 2rem;
    display: flex;
    justify-content: center;
    width: 83%;
  }

  .settings-sidebar {
    min-width: 230px;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 0.625rem;
  }

  .settings-main {
    width: 100%;
  }

  .block-title{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #113b71;
    padding-bottom: 10px;
    padding-left: 8px;
    display: flex;
    align-items: center;
  }

</style>