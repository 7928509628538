<template>
  <div>
    <v-file-input
        background-color="#0000000F"
        v-model="file"
        accept="image/*"
        show-size
        :label="$t('main.FILLED_INPUT')"
        prepend-icon=""
        prepend-inner-icon="attach_file"
        class="file-input-field"
        :messages="showMessage">
    </v-file-input>
    <v-btn v-if="file" color="primary" @click="submit">{{ $t("main.LOGO_UPLOAD_ACTION") }}</v-btn>
  </div>
</template>

<script>
import axiosClient from '@/axiosClient';
import { backendAuthUrl } from '@/settings';

export default {
  name: "OrganisationLogoUpload",
  props: [
    'organisation',
    'logoUrl'
  ],
  data() {
    return {
      file: null
    };
  },
  computed: {
    showMessage(){
      return !this.logoUrl ? this.$t('main.NO_LOGO_UPLOADED_YET') : '';
    }
  },
  methods: {
    submit() {
      let formData = new FormData();
      formData.append("organisation_id", this.organisation);
      formData.append("logo", this.file);

      axiosClient
        .post(backendAuthUrl + "/organisation/upload/logo", formData)
        .then(() => {
          this.file = null
          this.$emit('upload')
        })
        .catch(() => {
          this.file = null
        });
    }
  }
};
</script>

<style scoped>
  .file-input-field /deep/ .v-input__slot{
    min-height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .file-input-field /deep/ .v-input__prepend-inner{
    padding-top: 4px;
  }

  .file-input-field /deep/ .v-label{
    font-size: 15px;
  }
</style>