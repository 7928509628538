<template>
  <div>
    <v-list class="setting-panel" shaped>
      <v-subheader>{{ $t("main.SETTINGS").toUpperCase() }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          :to="{name: 'profile-edit'}"
          >
          <v-list-item-icon>
              <Svg-icon type="mdi" :path="accountIcon"></Svg-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("main.PROFILE") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="$hasPermission('a3m_core.DestinationManager')"
          :to="{name: 'organisation-countries-selection'}"
          >
          <v-list-item-icon>
              <Svg-icon type="mdi" :path="mapMarkerIcon"></Svg-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("main.COUNTRY_SELECTION") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
             v-if="$hasPermission('a3m_core.DestinationManager')"
            :to="{name: 'mytrip-filter-settings'}"
        >
          <v-list-item-icon>
              <Svg-icon type="mdi" :path="mytripMapIcon"></Svg-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("main.MYJOURNEY_PAGE") }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdilAccount } from '@mdi/light-js';
import { mdiMapMarkerStar, mdiMap } from '@mdi/js';

export default {
  name: "SettingsNavigation",
  components: {
    SvgIcon
  },
  data() {
    return {
      accountIcon: mdilAccount,
      mapMarkerIcon: mdiMapMarkerStar,
      mytripMapIcon: mdiMap
    }
  }
}
</script>

<style scoped>

  .setting-panel{
    border-radius: 10px !important;
  }

  .v-list-item__title {
    text-wrap: wrap;
  }
</style>